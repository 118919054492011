@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  width: 100%;
  max-width: 360px;
  margin: 0 0 20px 0;
  
  @media (--viewportMedium) {
    margin: 0 20px 0 0;
    max-width: 310px;
  }
}

.rooLink {
  color: var(--colorGrey700);

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.navLeft,
.navRight {
  position: absolute;
  width: 24px;
  height: 24px;
  border: 0;
  padding: 0;
  z-index: 1;

  top: calc(50% - 12px);

  /* center content */
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;

    & .navArrowWrapper {
      background-color: rgba(255, 255, 255, 1);
    }
  }
}

.navLeft {
  left: 10px;

  & .navArrowWrapper {
    padding-right: 2px;
  }
}

.navRight {
  right: 10px;

  & .navArrowWrapper {
    padding-left: 2px;
  }
}

.navArrowWrapper {
  display: none;

  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.75);
  background: rgba(255, 255, 255, 0.5);

  @media (--viewportLarge) {
    /* center content */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & svg {
    transform: scale(0.8);
  }
}

.itemWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    padding-bottom: 254px !important;
  }
}

.itemCentering {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.item {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2px;
}

.noImage {
  border: 1px solid var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
}

.orderPanelTitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  margin: 0 0 5px;
}

.productGallery {
  margin: 0 0 5px 0;

  & :global(.image-gallery-slide-wrapper) {
    border: none;
  }

  & :global(.image-gallery-bullets .image-gallery-bullet) {
    padding: 3px;
    margin: 0 3px;
    border-color: var(--colorGrey300);
  }
}

.productGallerySingle {
  & :global(.image-gallery-bullets .image-gallery-bullet) {
    display: none;
  }
}

.listingLocation {
  font-size: 12px;
  line-height: 18px;
  margin: 0 0 5px;

  & svg {
    flex-shrink: 0;
    margin-right: 2px;
  }
}

.location {
  font-weight: 400;
  margin-right: 5px;
}

.pricesContainer {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;

  & li {
    padding-top: 0;
  }
}

.priceItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.priceItemDaily {
  order: -1;
}

.priceTime {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.5;
}

.priceAmount {
  font-size: 11px;
  font-weight: 400;
  line-height: 1.5;
}

.totalDivider {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin: 10px 0;

  border: none;
  background-color: var(--colorGrey300);
}

.reviewUser {
  font-weight: 400;
  font-size: 12px;
}

.reviewsContainer {
  display: flex;
  justify-content: space-between;
}

.reviewCount {
  font-size: 12px;
  font-weight: 400;
}

.additionalRentals {
  font-size: 12px;
  font-weight: 600;
  margin-right: 10px;
}

.additionalRentalItem {
  margin-right: 10px;

  & svg {
    fill: none;
  }
}

.reviewRate {
  font-size: 12px;
  font-weight: 600;
  margin-right: 2px;
}

.link {
  white-space: nowrap;
  font-size: 12px;
  font-weight: 400;
  color: var(--marketplaceColor);
}

.additionalRentalsContainer {
  display: flex;
  align-items: baseline;
}

.sectionHeading,
.sectionHeadingWithExtraMargin {
  /* Font for sections titles */
  composes: p from global;
  padding: 0;
  font-weight: var(--fontWeightBold);

  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.sectionHeadingWithExtraMargin {
  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.favoritesContainer {
  position: absolute;
  z-index: 1;
  right: 10px;
  top: 10px;
  cursor: pointer;

  & svg {
    width: 24px;
    height: 24px;
  }
}

